<template>
  <div>
    <div @click="openPicker">
      <slot />
    </div>
    <SwitchStudentPopup ref="registrationSwitchStudentPopup" :is-add-cart="true" @switch-success="switchSuccessHandler" />
    <ConfirmDialog :show.sync="confirmDialogConfig.show" :title="confirmDialogConfig.title" :content="confirmDialogConfig.content"
                   :main-btn-text="confirmDialogConfig.mainBtnText" :sub-btn-text="confirmDialogConfig.subBtnText"
                   @mainBtnClick="confirmDialogConfig.mainBtnClick" @subBtnClick="confirmDialogConfig.subBtnClick"
    >
      <template v-if="signUpStatus == 1 || signUpStatus == 2" v-slot:content-bottom>
        <div class="evaluation-redeem-code-entrance">
          <p class="desc-text font-medium" @click="openEvaluationRedeemDialog">
            {{ $t('coursesDetail.redeemCodeEntrance[0]') }} <span class="btn-text hover-transition">{{ $t('coursesDetail.redeemCodeEntrance[1]') }}</span>
          </p>
        </div>
      </template>
    </ConfirmDialog>
    <RedeemDialog ref="evaluationRedeemDialog" origin="registration" :course-detail="orderInfo" @main-click="redeemMainClickHandler" />
  </div>
</template>
<script >
import { oneApi } from 'config/api';
import SwitchStudentPopup from 'components/courses/SwitchStudentPopup/index.vue'
import evaluation from 'common/evaluation.js';
import ConfirmDialog from 'components/common/ConfirmDialog/index.vue';
import Cookies from 'js-cookie';
import RedeemDialog from 'components/test/RedeemDialog';

export default {
  components: { SwitchStudentPopup, ConfirmDialog, RedeemDialog },
  props: {
    orderInfo: {
      default: () => {},
      type: Object,
    },
    skuId: {
      default: -1,
      type: Number,
    },
    btnDisabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isAppJsInject: false,
      studentInfo: {},
      evaluation: {
        entranceStatus: 0,
        examId: '',
      },
      confirmDialogConfig: {
        show: false,
        title: '',
        content: '',
        mainBtnText: '',
        mainBtnClick: () => {},
        subBtnText: '',
        subBtnClick: () => {},
      },
      // 报班资格校验结果
      signUpStatus: 0,
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.ucenter.isLogin;
    },
    loginPath() {
      const currentFullPath = this.$route.fullPath
      const successUrl = encodeURI(currentFullPath)
      return `/login?successUrl=${successUrl}&triggerSource=header`;
    },
  },
  mounted() {
    this.isAppJsInject = process.client ? !!window.JsInjectionActivity : false;
  },
  methods: {
    // 兑换成功后按钮点击事件处理
    redeemMainClickHandler(redeemResult) {
      if (!redeemResult.isRedeem) {
        // 继续报名
        const params = {
          studentId: this.studentInfo.uid,
          skuId: this.skuId,
        }
        this.addCart(params)
      } else {
        const code = redeemResult.redeemCode || ''
        this.$router.push(`/test/recommended-classes/${code}?studentId=${this.studentId}`)
      }
    },
    // 神策埋点-诊断兑换码弹窗曝光
    evaluationRedeemDialogShow() {
      this.Sensors.track('qualification_code_pv', {
        course_id: this.orderInfo.spec.courseId,
        class_id: this.orderInfo.spec.clazzId,
        previous_source: '班级详情页',
      })
    },
    // 诊断兑换码入口点击事件
    openEvaluationRedeemDialog() {
      // 关闭当前弹窗
      this.setConfirmDialog({ show: false })
      this.$refs.evaluationRedeemDialog.openDialog()
      this.evaluationRedeemDialogShow()
    },
    async openPicker() {
      if (this.isAppJsInject && Cookies.get('_app_token')) {
        this.$store.commit('ucenter/SET_IS_LOGIN', true);
      }
      if (!this.isLogin) {
        this.$router.push(this.loginPath)
        return;
      }
      if (this.btnDisabled) {
        console.log('不可加购');
        return
      }
      await this.$refs.registrationSwitchStudentPopup.openPicker()
      console.log('openPicker done');
    },
    async addCart(params) {
      try {
        const { code, msg, data } = await this.$axios.post(oneApi.addCart, params, {
          rewritePostBody: true,
        });
        this.$store.dispatch('cart/getCartCount', { $axios: this.$axios, studentId: params.studentId })
        if (code === 0) {
          const { status, statusDesc } = data
          if (status === 0) {
            this.$Toast.success(this.$t('order.shoppingCart.addedSuccess'));
            this.$refs.evaluationRedeemDialog.closeDialog()
          } else if (status === 1 || status === 2) {
            this.setSignUpCheckInfoDialog(data)
          } else {
            this.$Toast.fail(statusDesc || 'system error');
          }
        } else {
          this.$Toast.fail(msg || 'system error');
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 报名资格校验结果
    setSignUpCheckInfoDialog(helper) {
      const { status } = helper;
      this.signUpStatus = status;
      if (status === 2) {
        this.setConfirmDialog({
          show: true,
          title: this.$t('coursesDetail.signUpCheckInfoDialog.case2.unableSignUp'),
          content: helper.statusDesc || this.$t('coursesDetail.signUpCheckInfoDialog.case2.msg'),
          mainBtnText: this.$t('coursesDetail.signUpCheckInfoDialog.case2.ok'),
          mainBtnClick: () => {
            // 美分&非数学学科
            const subjuetFullName = this.orderInfo.spec.subjectFullName.toLocaleLowerCase() || ''
            if (this.$store.state.locale === 'us' && !subjuetFullName.includes('math')) {
              this.$router.push('/1-to-1-assessment');
              return
            }
            this.confirmDialogConfig.show = false;
          },
        })

        return
      }
      // 需要入学测，剩余作答机会>0
      const leftChance = helper.extra && helper.extra.leftChance
        ? helper.extra.leftChance
        : 0;
      const examId = helper.extra && helper.extra.examId ? helper.extra.examId : '';
      const queryObj = {
        courseId: this.orderInfo.spec.courseId || '',
        skuId: this.orderInfo.id,
      };
      const isTmc = this.$store.state.locale === 'tmc';
      this.setConfirmDialog({
        show: true,
        title: this.$t('coursesDetail.signUpCheckInfoDialog.case1.title'),
        content: this.$t(
          'coursesDetail.signUpCheckInfoDialog.case1.content',
          [
            leftChance,
            leftChance > 1 ? 's' : '',
            isTmc
              ? `您的孩子需要通过入学诊断，然后才能报名参加这个课程，只有${leftChance}次机会`
              : '',
          ],
        ),
        mainBtnText: this.$t(
          'coursesDetail.signUpCheckInfoDialog.case1.mainBtnText',
        ),
        mainBtnClick: () => {
          // 美分&非数学学科
          const subjuetFullName = this.orderInfo.spec.subjectFullName.toLocaleLowerCase() || '';
          if (
            this.$store.state.locale === 'us'
                && !subjuetFullName.includes('math')
          ) {
            this.$router.push('/1-to-1-assessment');
            return;
          }
          if (!examId) {
            this.$Toast('no examId');
            return;
          }
          evaluation.toEvaluation(
            this.$store,
            `/evaluation/instruction/${examId}`,
            queryObj,
            'class_register_pop_up',
          );
          this.startTestClick(examId);
        },
        subBtnText: this.$t(
          'coursesDetail.signUpCheckInfoDialog.case604.subBtnText',
        ),
        subBtnClick: () => {
          this.confirmDialogConfig.show = false;
        },
      });
    },
    switchSuccessHandler(isSwitch, studentInfo) {
      this.studentInfo = studentInfo
      const params = {
        studentId: studentInfo.uid,
        skuId: this.skuId,
      }
      this.addCart(params)
    },
    // 设置确认弹窗
    setConfirmDialog(...args) {
      this.confirmDialogConfig = Object.assign(
        this.confirmDialogConfig,
        args[0],
      );
    },
    // 神策埋点-开始诊断
    startTestClick(examId) {
      this.Sensors.track('enroll_start_test_click', {
        course_id: this.orderInfo.spec.courseId,
        class_id: this.orderInfo.spec.clazzId,
        quiz_id: examId,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
// 诊断兑换码入口
.evaluation-redeem-code-entrance {
  margin: px2vw(32) px2vw(48) 0;
  padding: px2vw(16);
  font-size: px2vw(24);
  line-height: px2vw(28);
  text-align: center;
  border-radius: px2vw(20);
  color: var(--txt-color-lv2);
  background: rgba(244, 246, 250, 0.5);

  .btn-text {
    color: var(--txt-color-link);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: var(--txt-color-link2);
    }
  }
}
</style>
